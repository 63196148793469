


























































































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';
import customersService from '@/services/customer.service';
import CustomerEditModal from '@/components/customers/CustomerEditModal.vue';
import { Gender } from '@/model/gender.ts';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCustomersStore } from '@/store/customers-store';
import { Customer } from '@/model/customer';
import { useUsersStore } from '@/store/users-store';
import { ConflictEventAction } from '@/constants';
import { useUiStore } from '@/store/ui-store';

export default Vue.extend({
  components: {
    CheckEventsConflictsModal,
    LoadingIndicator,
    CustomerEditModal
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      GENDER: Gender,
      actions: [ConflictEventAction.CANCEL]
    };
  },
  computed: {
    ...mapStores(useCustomersStore, useUsersStore, useUiStore),
    customer(): Customer {
      return this.customersStore.currentEntity;
    },
    isFetchingCustomer(): boolean {
      return this.customersStore.fetching;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    }
  },
  methods: {
    openEditCustomerModal() {
      this.$bvModal.show('customer-edit-modal');
    },
    openVerifyDeleteModal() {
      this.$bvModal.show('check-events-conflicts-modal');
    },
    async verifyRequest() {
      await customersService.verifyDeleteCustomer(this.customer.id);
    },
    async deleteRequest() {
      return customersService.deleteCustomer(this.customer.id);
    },
    deleted() {
      this.$router.push({ name: 'Customers' });
      this.uiStore.alertSuccess(this.$t('toast.customerDeleted'));
    }
  }
});
