






































































































































import TextareaInput from '@/components/shared/forms/TextareaInput.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import FetchFailure from '@/components/shared/FetchFailure.vue';
import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCustomersStore } from '@/store/customers-store';
import { Customer } from '@/model/customer';
import { Comment } from '@/model/comment';

export default Vue.extend({
  components: {
    TextareaInput,
    LoadingIndicator,
    EmptyDataset,
    FetchFailure,
    ConfirmModal
  },
  data() {
    return {
      currentPage: 1,
      commentModal: {
        content: '',
        title: '',
        type: '',
        commentId: null as number | null
      },
      MODAL_TYPE: {
        EDIT: 'EDIT',
        ADD: 'ADD'
      },
      deleteCommentModal: {} as IConfirmModal
    };
  },
  computed: {
    ...mapStores(useCustomersStore),
    customer(): Customer {
      return this.customersStore.currentEntity;
    },
    comments(): Comment[] {
      return this.customersStore.comments.entities;
    },
    rows(): number {
      return this.customersStore.comments.pagination.count;
    },
    pageCount(): number {
      return this.customersStore.comments.pagination.page_count;
    },
    isFetching(): boolean {
      return this.customersStore.comments.fetching;
    },
    errorOccurred(): boolean {
      return this.customersStore.comments.failure;
    },
    isSaving(): boolean {
      return this.customersStore.comments.saving;
    },
    validationErrors(): {} {
      return this.customersStore.comments.validationErrors;
    }
  },
  mounted() {
    this.deleteCommentModal = (this.$refs
      .deleteCommentModal as unknown) as IConfirmModal;
    this.fetchComments();
  },
  methods: {
    fetchComments() {
      this.customersStore.getComments(this.customer.id, this.currentPage);
    },
    openEditCommentModal(comment) {
      this.commentModal = {
        content: comment.comment,
        title: 'Modifier un commentaire',
        type: this.MODAL_TYPE.EDIT,
        commentId: comment.id
      };
      this.$bvModal.show('comment-modal');
    },
    openAddCommentModal() {
      this.commentModal = {
        content: '',
        title: 'Ajouter un commentaire',
        type: this.MODAL_TYPE.ADD,
        commentId: null
      };
      this.$bvModal.show('comment-modal');
    },
    async openDeleteCommentModal(comment) {
      this.deleteCommentModal.openModal(
        `Veuillez confirmer la suppression du commentaire de ${comment.employee.firstname} ${comment.employee.lastname} à l'aide du bouton "Supprimer" ci-dessous.`,
        {
          okTitle: 'Supprimer',
          okVariant: 'danger'
        },
        comment
      );
    },
    async onConfirmCommentDeletion(comment) {
      const commentSuccessfullyDeleted = await this.customersStore.deleteComment(
        comment.id,
        this.customer.id,
        this.currentPage
      );
      this.deleteCommentModal.sendingData = false;
      if (commentSuccessfullyDeleted) {
        this.deleteCommentModal.closeModal();
        this.fetchComments();
      }
    },
    async onSave(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.commentModal.type === this.MODAL_TYPE.EDIT) {
        const commentEdited = await this.customersStore.editComment(
          this.commentModal.commentId as number,
          this.commentModal.content,
          this.customer.id,
          this.currentPage
        );
        if (commentEdited) {
          this.$bvModal.hide('comment-modal');
        }
      } else if (this.commentModal.type === this.MODAL_TYPE.ADD) {
        const commentAdded = await this.customersStore.addComment(
          this.customer.id,
          this.commentModal.content
        );
        if (commentAdded) {
          this.$bvModal.hide('comment-modal');
        }
      }
    }
  }
});
