




















import CustomerForm from './CustomerForm.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCustomersStore } from '@/store/customers-store';
import { Customer } from '@/model/customer';

export default Vue.extend({
  components: {
    CustomerForm
  },
  computed: {
    ...mapStores(useCustomersStore),
    customer(): Customer {
      return this.customersStore.currentEntity;
    },
    validationErrors(): {} {
      return this.customersStore.validationErrors;
    },
    saving(): boolean {
      return this.customersStore.saving;
    }
  },
  methods: {
    async editCustomer(bvModalEvt) {
      bvModalEvt.preventDefault();
      await this.customersStore.edit((this.$refs as any).form.dataModelForApi);
      this.$bvModal.hide('customer-edit-modal');
    }
  }
});
