






























import CustomerDetails from './view/CustomerDetails.vue';
import CustomerOpenBookings from './view/CustomerOpenBookings.vue';
import CustomerClosedBookings from './view/CustomerClosedBookings.vue';
import CustomerComments from './view/CustomerComments.vue';
import CustomerFidelityVouchers from '@/components/customers/view/CustomerFidelityVouchers.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import FetchFailure from '@/components/shared/FetchFailure.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCustomersStore } from '@/store/customers-store';
import { Customer } from '@/model/customer';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  components: {
    CustomerFidelityVouchers,
    CustomerDetails,
    CustomerOpenBookings,
    CustomerClosedBookings,
    CustomerComments,
    LoadingIndicator,
    FetchFailure,
    BackButtonTitle
  },
  data() {
    return {
      user: {},
      cardsReadyCount: 0,
      message: '',
      modalValue: ''
    };
  },
  computed: {
    ...mapStores(useCustomersStore, useUsersStore),
    customer(): Customer {
      return this.customersStore.currentEntity;
    },
    isFetching(): boolean {
      return this.customersStore.fetching;
    },
    errorOccurred(): boolean {
      return this.customersStore.failure;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    }
  },
  created() {
    this.fetchCustomer();
  },
  methods: {
    fetchCustomer() {
      this.customersStore.getCustomer(this.$route.params.id);
    }
  }
});
